import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _10_45auth_45guard_45global from "/app/middleware/10.auth-guard.global.ts";
import _20_45completion_45guard_45global from "/app/middleware/20.completion-guard.global.ts";
import _30_45mconnect_45guard_45global from "/app/middleware/30.mconnect-guard.global.ts";
import _40_45switch_45lang_45guard_45global from "/app/middleware/40.switch-lang-guard.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _10_45auth_45guard_45global,
  _20_45completion_45guard_45global,
  _30_45mconnect_45guard_45global,
  _40_45switch_45lang_45guard_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "developer-role-guard": () => import("/app/middleware/developer-role-guard.ts"),
  "individual-type-guard": () => import("/app/middleware/individual-type-guard.ts"),
  "mconnect-completion-guard": () => import("/app/middleware/mconnect-completion-guard.ts"),
  "registration-type-guard": () => import("/app/middleware/registration-type-guard.ts"),
  "user-type-guard": () => import("/app/middleware/user-type-guard.ts")
}